import styled from "@emotion/styled";
const ThemeWrapper = styled("div")`
  height: 100%;
  margin: 0;
  line-height: 1.5;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.body};
  transition: all 0.5s ease-out;
  h1,
  h2,
  h3 {
    &.sm-heading {
      background: rgba(lighten(${(props) => props.theme.background}, 2), 0.5);
    }
  }

  a {
    color: ${(props) => props.theme.body};
    text-decoration: none;
  }

  .text-secondary {
    color: ${(props) => props.theme.secondary};
  }

  main {
    .icons {
      a {
        color: ${(props) => props.theme.body};
        &:hover {
          color: ${(props) => props.theme.secondary};
        }
      }
    }
  }
  button:focus {
    outline: 0;
  }

  .dark-mode-toggler {
    color: ${(props) => props.theme.body};
  }

  // About Me
  .about-info {
    .bio-image {
      border: ${(props) => props.theme.secondary} 3px solid;
    }

    .job {
      background: ${(props) => props.theme.backgroundMod};
      border-bottom: ${(props) => props.theme.secondary} 5px solid;
    }
    .edu-b {
      background: ${(props) => props.theme.backgroundMod};
      border-bottom: ${(props) => props.theme.secondary} 5px solid;
    }
    .skill-1 {
      background: ${(props) => props.theme.backgroundMod};
      border-bottom: ${(props) => props.theme.secondary} 5px solid;
    }
    .skill-2 {
      background: ${(props) => props.theme.backgroundMod};
      border-bottom: ${(props) => props.theme.secondary} 5px solid;
    }
    .icon-list-item i {
      &:hover {
        color: ${(props) => props.theme.secondary};
      }
    }
    .custom-icon {
      fill: ${(props) => props.theme.body};
      &:hover {
        fill: ${(props) => props.theme.projectButton};
      }
    }
  }

  // Work/Projects
  .projects {
    img {
      border: 3px ${(props) => props.theme.background} solid;

      // &:hover {
      //   border-color: ${(props) => props.theme.secondary};
      // }
    }
  }
  .boxes {
    div {
      &:hover {
        background: ${(props) => props.theme.secondary};
        color: ${(props) => props.theme.background};
        span {
          color: ${(props) => props.theme.background};
        }
      }
    }
  }
  // Button Styles
  .btn {
    &:hover {
      background: ${(props) => props.theme.secondary};
      color: ${(props) => props.theme.body};
    }
  }

  .btn-project {
    background: ${(props) => props.theme.projectButton};
    color: ${(props) => props.theme.body};
  }

  .btn-github {
    background: ${(props) => props.theme.githubButton};
    color: ${(props) => props.theme.body};
  }

  // Menu Button
  .menu-btn {
    .btn-line {
      background: ${(props) => props.theme.body};
    }
  }

  // Menu overlay
  .menu {
    &-nav {
      background: ${(props) => props.theme.backgroundMod};
    }
    //   Branding Side
    &-branding {
      background: ${(props) => props.theme.background};
      // .portrait {
      //   border: solid 3px ${(props) => props.theme.secondary};
      // }
    }

    .nav-item {
      &.current > a {
        color: ${(props) => props.theme.secondary};
      }
    }
  }

  // Delay each nav item slide by 0.1s
  .nav-link {
    color: ${(props) => props.theme.body};

    &:hover {
      color: ${(props) => props.theme.secondary};
    }
  }
`;

export default ThemeWrapper;
