import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer id="main-footer">Copyright &copy; 2023 Walaa Sarsour</footer>
      </div>
    );
  }
}
