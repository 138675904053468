import React from "react";

const visualstudiocode = () => {
  return (
    <svg
      height="48"
      width="48"
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Visual Studio Code icon</title>
      <path d="M24 2.5v19L18 24 0 18.5v-.561l18 1.545V0zM1 13.111L4.385 10 1 6.889l1.418-.827L5.853 8.65 12 3l3 1.456v11.088L12 17l-6.147-5.65-3.434 2.589zM7.644 10L12 13.283V6.717z" />
    </svg>
  );
};

export default visualstudiocode;
