import React from "react";
import { useTheme } from "../ThemeContext";

const ThemeToggler = () => {
  const themeState = useTheme();
  return (
    <div>
      <button className="dark-mode-toggler" onClick={() => themeState.toggle()}>
        <span className="fa-stack">
          <i className="fas fa-moon fa-2x" />
        </span>
      </button>
    </div>
  );
};

export default ThemeToggler;
