import React, { Component } from "react";

class Landing extends Component {
  render() {
    return (
      <div>
        <main id="home">
          <h1 className="lg-heading">
            I'm <span className="text-secondary">Walaa</span>
          </h1>
          <h2 className="sm-heading">I make things and solve problems</h2>
          <div className="icons">
            <a href="https://github.com/wsarsou" className="theme">
              <i className="fab fa-github fa-2x" />
            </a>
          </div>
        </main>
      </div>
    );
  }
}
export default Landing;
