import React, { Component } from "react";
import LogoCss3 from "../svg/css3";
import LogoGit from "../svg/git";
import LogoHtml5 from "../svg/html5";
import LogoJavascript from "../svg/javascript";
import LogoMicrosoftexcel from "../svg/microsoftexcel";
import LogoMongodb from "../svg/mongodb";
import LogoNetlify from "../svg/netlify";
import LogoNpm from "../svg/npm";
import LogoPostman from "../svg/postman";
import LogoReact from "../svg/react";
import LogoSass from "../svg/sass";
import LogoVisualstudiocode from "../svg/visualstudiocode";

class About extends Component {
  render() {
    return (
      <div>
        <main id="about">
          <h1 className="lg-heading">
            about
            <span className="text-secondary"> me</span>
          </h1>
          <div className="about-info">
            <div className="bio">
              <p>I'm a Raleigh-based developer & learner</p>
              <p>
                In my free time, I like to explore new development languages &
                create 2D games!
              </p>
            </div>
            <h1 className="text-secondary exp-h">Work Experience</h1>

            <div className="job job-1">
              <h3>West Cary Middle School</h3>
              <h6>
                8th Grade Math Teacher & Team Lead-{" "}
                <span className="date-text">August 2020 - Present</span>
              </h6>
              <p>
                Taught advanced highschool, academic, & special education math
                courses. Planned differentiated instruction by designing and
                implementing educational technology in purposeful ways to
                directly enhance student learning, differentiate instruction for
                diverse needs, & increase student engagement and proficiency.
              </p>
              <p> Examples include:</p>
              <ol>
                <li>
                  Incorporated formative assessment tools such as Nearpod &
                  Google apps into lessons to optimize student experiences &
                  engagement as the users, & to effectively assess student
                  understanding to drive the lesson planning process.
                </li>
                <li>
                  Used the Desmos Mathematics Computation Tool to develop
                  interactive lessons using its Computation Layer (CL) to code &
                  automatically grade answers & provide immediate, responsive
                  feedback to students.
                </li>
                <li>
                  Applied Canvas LMS features like canvas studio to create
                  videos with embedded assessment questions, annotation tools
                  for graphing, discussion assignments for student input &
                  interaction, & embedded html code to insert calculators &
                  quizzes directly into assignment pages.
                </li>
                <li>
                  Set up an automatic grading system by linking canvas grades
                  directly to PowerSchool gradebook to automatically compile,
                  calculate & update student grades on a daily basis.
                </li>
              </ol>
            </div>
            <h1 className="text-secondary edu-h">Education</h1>
            <div className="edu-b learning">
              <h3>NC State University</h3>
              <h6>
                Bachelor of Science -{" "}
                <span className="date-text">January 2015 - December 2019</span>
              </h6>
              <h3>Degree Honors:</h3>
              <h6>
                Valedictorian, Dean's List 2015-2019, Summa Cum Laude, Colonel
                Rodman Scholarship, Marvin B. Smith Scholarship
              </h6>
              <p>Mathematics Education</p>
            </div>
            <h1 className="text-secondary skill-h">Skills</h1>
            <div className="skill-1 learning">
              <h3>Languages and Frameworks</h3>
              <ul className="icon-list dev-icons">
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoJavascript />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoReact />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoHtml5 />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoCss3 />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoSass />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoGit />
                  </i>
                </li>
              </ul>
            </div>
            <div className="skill-2 learning">
              <h3>Software</h3>
              <ul className="icon-list dev-icons">
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoMicrosoftexcel />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoMongodb />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoNetlify />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoNpm />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoPostman />
                  </i>
                </li>
                <li className="icon-list-item">
                  <i className="custom-icon">
                    <LogoVisualstudiocode />
                  </i>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default About;
