import React, { Component } from "react";
import { Link } from "react-router-dom";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //  Since not using hte github profile info anywehre else, this data will NOT be stored in redux, only in the current state. As such, no need for redux here
      // this info comes from github oauth
      // https://github.com/settings/applications/1038399
      clientId: "4a210ded3e7345b001e7",
      clientSecret: "76220e769e9253d8caabc820e2c0325d46b2909c",
      // Here will dictate how many and how to sort the data (20 repos, sort by oldest to newest)
      count: 20,
      sort: "created: asc",
      repos: [],
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  // make request to github api
  componentDidMount() {
    // destructure things from state
    const { count, sort, clientId, clientSecret } = this.state;
    // use fetch api for the get request, include the username, repos, count, and how they are sorted, then client id and cilent secret
    fetch(
      `https://api.github.com/users/wsarsou/repos?per_page=${count}&sort=${sort}&client_id=${clientId}&client_secret=${clientSecret}`
    )
      // this givdes us a promise back that we need to map to json
      .then((res) => res.json())
      // this gives us data, which will set the state, where we set the repos to the data coming in
      .then((data) => {
        if (this.refs.myRef) {
          this.setState({ repos: data });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    // pull out repos from the state
    const { repos } = this.state;
    // create repoItems variables and map the repos
    const repoItems = repos.map((repo) => (
      // set that to a div, with the key of the repo id (each repo will be diesplayed in teh div)
      <div key={repo.id} className="item">
        <h4>
          <Link
            to={{ pathname: repo.html_url, replace: true }}
            className="text-info"
            target="_blank"
          >
            {repo.name}
          </Link>
        </h4>
        <div className="img__wrap">
          <img src={`../img/projects/${repo.name}.png`} alt="Project" />
          <p className="img__description">{repo.description}</p>
        </div>

        <Link
          to={{ pathname: repo.homepage, replace: true }}
          target="_blank"
          className="btn btn-project "
        >
          <i className="fas fa-eye" /> Project
        </Link>
        <Link
          to={{ pathname: repo.html_url, replace: true }}
          target="_blank"
          className="btn btn-github "
        >
          <i className="fas fa-eye" /> Github
        </Link>
      </div>
    ));
    return (
      <div className="work">
        <main id="work">
          <h1 className="lg-heading">
            my
            <span className="text-secondary"> projects</span>
          </h1>
          <h2 className="sm-heading">some of the things I've done...</h2>
          <div ref="myRef" className="projects">
            {repoItems}
          </div>
        </main>
      </div>
    );
  }
}

export default Projects;
