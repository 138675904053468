const white = "#FFFFFF";
const darkWhite = "#EAEAEA";
const black = "#000000";
const lightBlack = "#2B2B2B";
const red = "#cc0000";
const redAlt1 = "#AA2A2A";
const redAlt2 = "#D15151";
const opacity = "0.9";

const themeLight = {
  background: white,
  backgroundMod: darkWhite,
  body: black,
  secondary: red,
  projectButton: redAlt1,
  githubButton: redAlt2,
  backgroundOpacity: opacity
};

const themeDark = {
  background: black,
  backgroundMod: lightBlack,
  body: white,
  secondary: red,
  projectButton: redAlt2,
  githubButton: redAlt1,
  backgroundOpacity: opacity
};

const theme = mode => (mode === "dark" ? themeDark : themeLight);

export default theme;
