// Define the default state for the light/dark theme, then later use teh useTheme hook
import React, { useState, useContext, createContext, useEffect } from "react";
import { ThemeProvider as EmotionThemeProvider } from "emotion-theming";
import theme from "./theme";

const defaultContextData = {
  dark: false,
  toggle: () => {},
};

const ThemeContext = createContext(defaultContextData);
const useTheme = () => useContext(ThemeContext);

// This is the custom hook that will use the dark mode function to change the state. This hook will set a local state for the theme state when mounting the app.
const useEffectDarkMode = () => {
  // Initialize theme state
  const [themeState, setThemeState] = useState({
    dark: false,
    hasThemeMounted: false,
  });
  useEffect(
    () => {
      // First define the local state as dark, if that is what is in the local storage from the previous session
      const lsDark = localStorage.getItem("dark") === "true";
      //   Then, set the theme state when the component mounts
      setThemeState({ dark: lsDark, hasThemeMounted: true });
    },
    // Pass an empty array as the second argument of this useEffect hook to make sure that the useEffect hook is only called when the ThemeProvider component mounts, otherwise it would be called every time ThemeProvider renders
    []
  );
  return [themeState, setThemeState];
};

// ThemeProvider code
const ThemeProvider = ({ children }) => {
  // Set the state to the useEffectDarkMode function/hook
  const [themeState, setThemeState] = useEffectDarkMode();
  //
  if (!themeState.hasThemeMounted) {
    // If the theme is still loading, hold off on rendering. This is a workaround to avoidrendering the app in lightmode by default, then switching to darkmode after it gets the theme state from local storage
    return <div />;
  }
  const toggle = () => {
    // This is where the toggle function code goes
    // When clicked, reverse the dark state
    const dark = !themeState.dark;
    // Set the localStorage dark item with the above modified state
    localStorage.setItem("dark", JSON.stringify(dark));
    // Set the theme with the new dark value (true or false) state
    setThemeState({ ...themeState, dark });
  };

  // Turnery operator that checks whether the dark value is set to true in the state, and modifies the theme accordingly
  const computedTheme = themeState.dark ? theme("dark") : theme("light");

  return (
    <EmotionThemeProvider theme={computedTheme}>
      <ThemeContext.Provider
        value={{
          dark: themeState.dark,
          toggle,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionThemeProvider>
  );
};

// Export
export { ThemeProvider, useTheme };
