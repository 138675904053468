import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <button className="home-button">
        <Link to="/">
          <span className="fa-stack">
            <i className="fas fa-home fa-2x" />
          </span>
        </Link>
      </button>
    </div>
  );
};

export default Home;
