import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <main id="contact">
          <h1 className="lg-heading">
            contact
            <span className="text-secondary"> me</span>
          </h1>
          <div className="boxes">
            <div className="theme ">
              <span className="text-secondary">Email: </span>
              walaahsarsour@gmail.com
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default Contact;
