import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  constructor() {
    super();
    this.state = { showMenu: false };
    this.onShowMenuClick = this.onShowMenuClick.bind(this);
  }

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  componentDidMount() {
    document.addEventListener("click", this.onShowMenuClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onShowMenuClick, false);
  }

  onShowMenuClick(e) {
    this.setState({
      showMenu: !this.state.showMenu,
    });

    // Select DOM Items
    const menuBtn = document.querySelector(".menu-btn");
    const menu = document.querySelector(".menu");
    const menuNav = document.querySelector(".menu-nav");
    const menuBranding = document.querySelector(".menu-branding");
    const navItems = document.querySelectorAll(".nav-item");

    if (!this.state.showMenu) {
      menuBtn.classList.add("close");
      menu.classList.add("show");
      menuNav.classList.add("show");
      menuBranding.classList.add("show");
      navItems.forEach((item) => item.classList.add("show"));

      // Set Menu State
      this.setState({
        showMenu: true,
      });
    } else {
      menuBtn.classList.remove("close");
      menu.classList.remove("show");
      menuNav.classList.remove("show");
      menuBranding.classList.remove("show");
      navItems.forEach((item) => item.classList.remove("show"));

      // Set Menu State
      this.setState({
        showMenu: false,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="menu-btn" onClick={this.onShowMenuClick.bind(this)}>
          <div className="btn-line" />
          <div className="btn-line" />
          <div className="btn-line" />
        </div>
        <div>
          <nav className="menu">
            <div className="menu-branding">
              <div className="portrait" />
            </div>
            <ul className="menu-nav">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={this.onShowMenuClick.bind(this)}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item" ref="menuItem">
                <Link
                  className="nav-link"
                  to="/about/"
                  onClick={this.onShowMenuClick.bind(this)}
                >
                  About Me
                </Link>
              </li>
              <li className="nav-item" ref="menuItem">
                <Link
                  className="nav-link"
                  to="/projects/"
                  onClick={this.onShowMenuClick.bind(this)}
                >
                  Projects
                </Link>
              </li>
              <li className="nav-item" ref="menuItem">
                <Link
                  className="nav-link"
                  to="/contact/"
                  onClick={this.onShowMenuClick.bind(this)}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Menu;
