import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Landing from "./Pages/Landing";
import About from "./Pages/About";
import Projects from "./Pages/Projects";
import Contact from "./Pages/Contact";
import Menu from "./Layout/Menu";
import ThemeToggler from "./Layout/ThemeToggler";
import Home from "./Layout/Home";
import ThemeWrapper from "./Layout/ThemeWrapper";
import Footer from "./Layout/Footer";

// Edit - this was brought in from the store.js file;
const middleware = [thunk];
const store = createStore(
  () => [],
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ThemeWrapper>
          <div className="App" />
          <div className="actions">
            <Menu />
            <ThemeToggler />
            <Home />
          </div>
          <div>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/about" component={About} />
              <Route path="/projects" component={Projects} />
              <Route path="/contact" component={Contact} />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </ThemeWrapper>
      </Router>
    </Provider>
  );
};

export default App;
